<template>
  <div class="page-wrapper">
    <!-- Header Image -->
    <div class="page-header" v-if="jobDetails && jobDetails.header_img">
      <img :src="$S3_bucket_url + jobDetails.header_img" alt="" class="header-image">
    </div>

    <div class="job-container">
      <!-- Job Header Block -->
      <div class="job-header">
        <div class="job-title-section">
          <div v-if="expired" class="alert alert-danger">
            Applications for this recruitment are closed now.
          </div>
          <div v-if="jobDetails && jobDetails.marquee_text && !expired" class="alert alert-info">
            {{ jobDetails.marquee_text }}
          </div>
          <h1 v-if="job && job.job_designation">{{ job.job_designation }}</h1>
        </div>

        <div class="company-info" v-if="job">
          <div class="logo-container" v-if="jobDetails && jobDetails.company_logo">
            <img :src="$S3_bucket_url + jobDetails.company_logo" :alt="job.company_name" class="company-logo">
          </div>
          <div>
            <h2 style="font-size: 1.5rem; font-weight: 700;">{{ job && job.company_name }}</h2>
            <div class="location" v-if="job && job.cities.length > 0" style="font-size: 1rem; font-weight: bold;">
              <!-- <i class="fas fa-map-marker-alt"></i> -->
              <span v-for="(city, index) in job.cities" :key="index">
                {{ city }}<span v-if="index < job.cities.length - 1">, </span>
              </span>
            </div>
          </div>
        </div>

        <div class="job-meta" v-if="job">
          <div class="badge" v-if="c_job_type">
            <i class="fas fa-briefcase"></i>
            {{ c_job_type }}
          </div>
          <div class="badge" v-if="job.salary_range_from && !job.salary_flag">
            <i class="fas fa-money-bill-wave"></i>
            ₹{{ job.salary_range_from }}<span v-if="job.salary_range_to"> - ₹{{ job.salary_range_to }}</span>
          </div>
          <div class="badge" v-if="job.last_date">
            <i class="fas fa-calendar"></i>
            Last Date: {{ job.last_date }}
          </div>
        </div>

        <div class="job-actions" v-if="job && !expired && !success_alert">
          <router-link 
            :to="{path: '/form/'+$route.params.private_job}" 
            class="btn btn-primary btn-large">
            Apply Now
          </router-link>
        </div>
        <div v-if="success_alert" :class="['alert', alert_class]">{{ success_msg }}</div>
      </div>

      <!-- Job Requirements Block -->
      <div class="job-description">
        <h3>Job Requirements</h3>
        <div class="description-content">
          <div class="eligibility-criteria">
            <h4>Eligibility Criteria</h4>
            <ul>
              <li v-if="job && job.courses">
                <span v-for="(course, index) in job.courses" :key="index">
                  {{ course.name }}<span v-if="index < job.courses.length - 1">, </span>
                </span>
                <span v-if="job.year_of_passing_from">
                  with year of passing {{ job.year_of_passing_from }}
                  <span v-if="job.year_of_passing_to"> - {{ job.year_of_passing_to }}</span>
                </span>
              </li>
              <li v-if="job && job.hq_marks_percentage_from">
                Candidate should have scored minimum {{ job.hq_marks_percentage_from }}% in Graduation, 12th & 10th
              </li>
              <li v-if="job && job.skills && job.skills.length">
                Skills: 
                <span v-for="(skill, index) in job.skills" :key="index">
                  {{ skill }}<span v-if="job.skills.length != index + 1">, </span>
                </span>
              </li>
            </ul>

            <h4 v-if="job && job.hiring_process">Selection Process</h4>
            <ul>
              <li v-if="job && job.hiring_process">{{ job.hiring_process }}</li>
            </ul>

            <h4 v-if="jobDetails && jobDetails.job_desc_pdf">Job Description</h4>
            <p v-if="jobDetails && jobDetails.job_desc_pdf">
              <a :href="$S3_bucket_url + jobDetails.job_desc_pdf" target="_blank">
                Click here to view detailed job description
              </a>
            </p>
          </div>

          <div v-if="job && job.job_description" class="full-description">
            <div v-html="job.job_description"></div>
          </div>
        </div>
      </div>

      <!-- Video Section -->
      <div class="video-section" v-if="jobDetails && jobDetails.job_video">
        <video controls style="width: 100%; max-width: 600px; margin: 2rem auto; display: block;">
          <source :src="$S3_bucket_url + jobDetails.job_video" type="video/mp4">
        </video>
      </div>

      <!-- About Company Section -->
      <section class="company-info-section" v-if="job && job.company_profile">
        <h3>About {{ job.company_name }}</h3>
        <p>{{ job.company_profile }}</p>
      </section>
    </div>

    <!-- Footer -->
    <footer class="footer">
      <div class="footer-content">
        <div class="copyright">
          © {{ new Date().getFullYear() }} 
          <!-- <img src="https://s3.ap-south-1.amazonaws.com/www.hireclap.com/Assets/images/hireclap-logo-HD.png" 
               alt="Hireclap" class="footer-logo"> -->
        </div>
        <div class="made-in">
          Made with <span class="heart">&hearts;</span> in India
        </div>
        <div class="social-links">
          <a href="https://www.facebook.com/HireClap" target="_blank">
            <v-icon style="color: #6b7280;">{{ $icons.facebook }}</v-icon>
          </a>
          <a href="https://twitter.com/Hireclap" target="_blank">
            <v-icon style="color: #6b7280;">{{ $icons.twitter }}</v-icon>
          </a>
          <a href="https://www.linkedin.com/company/hireclap" target="_blank">
            <v-icon style="color: #6b7280;">{{ $icons.linkedin }}</v-icon>
          </a>
          <a href="https://www.youtube.com/channel/UC_Rhjfe3TniDTGLn8nxcu0Q" target="_blank">
            <v-icon style="color: #6b7280;">{{ $icons.youtube }}</v-icon>
          </a>
          <a href="https://www.instagram.com/hireclap" target="_blank">
            <v-icon style="color: #6b7280;">{{ $icons.instagram }}</v-icon>
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import Actions from '../libraries/apiActions.js';
  import validation from '../libraries/validFormat' ;
  import '../assets/hire.css';
  export default {
    name: "recruiter_dashboard",
    metaInfo: (vue) => {
      const job = vue.job;
      const job_type = vue.c_job_type;
      const qualification = vue.qualification;
      const last_date_ts = vue.last_date_ts;
      const jobLocation = vue.jobLocation;
      const seo_title = vue.seo_title;
      const meta_desc = vue.meta_desc;
      const meta_keyword = vue.meta_keyword;

      return {
        title: seo_title ? seo_title : '',
        meta:[
          {name:'description',content: meta_desc ? meta_desc : ''},
          {name:'keywords',content: meta_keyword ? meta_keyword : ''},
        ],
        link: [
          {
            rel: "canonical",
            href: process.env.VUE_APP_URL + "jobs/" + vue.$route.params.job,
          },
        ],
        script: [
          {
            type: "application/ld+json",
            json: {
              "@context": "http://schema.org/",
              "@type": "JobPosting",
              datePosted: `${job ? new Date(job.date_of_posting * 1000) : ""}`,
              validThrough: `${job ? new Date(last_date_ts * 1000) : ""}`,
              title: `${job ? job.job_designation : ""}`,
              description: `${job ? job.job_description : ""}`,
              employmentType: `${job_type ? job_type : ""}`,
              industry: "Private",
              qualifications: `${qualification ? qualification : ""}`,
              responsibilities: "-",
              hiringOrganization: {
                "@type": "Organization",
                name: `${job ? job.company_name : ""}`,
              },
              jobLocation: jobLocation,
              applicantLocationRequirements: {
                "@type": "Country",
                name: "India",
              },
              baseSalary: {
                "@type": "MonetaryAmount",
                currency: "INR",
                value: {
                  "@type": "QuantitativeValue",
                  minValue: `${job ? (job.salary_range_from ? job.salary_range_from : 0) : ""}`,
                  maxValue: `${job ? (job.salary_range_to ? job.salary_range_to : job.salary_range_from) : ""}`,
                  unitText: "monthly",
                },
              },
            },
          },
        ],
      };
    },
    async beforeMount() {
      let url = this.$route.path;
      let url_split = url.split("-");
      let id = url_split[url_split.length - 1];

      if (Object.keys(this.$route.params).length && this.$route.params.success) {
        this.success_alert = true;
        this.success_msg   = this.$route.params.success;
        this.alert_class   = this.$route.params.status?'text-success':'text-danger';
      } else {
        this.success_alert = false;
      }
      this.getJobDetails(id);
      await this.getCities();
      this.getQuestionnaire(id);
    },
    data() {
      return {
        modals: {
          open: "exit",
        },
        action: new Actions(),
        rec_jobs:[],
        jobs:[],
        seo_title: '',
        meta_desc: '',
        meta_keyword: '',
        filter:{
          job_role:null,
          city_id:null,
          offset: 0,
          limit:9
        },
        show: 4,
        load_more: 0,
        job_flag:0,
        total:0,
        limit:4,
        validate: new validation(),
        job: null,
        qualification: "",
        last_date_ts: null,
        jobLocation: [],
        courses: [],
        branches:[],
        cities: [],
        all_cities: [],
        jobrole: null,
        job_type: [
          { value: 1, text: "Full time" },
          { value: 2, text: "Work from home" },
          { value: 3, text: "Hybrid" },
          { value: 4, text: "Part time" },
          { value: 5, text: "Internship" },
          { value: 6, text: "Apprenticeship" },
        ],
        exp: [
          { value: 1, text: "0 Month(Fresher)" },
          { value: 2, text: "06 Months" },
          { value: 3, text: "1 Year" },
          { value: 4, text: "2 year" },
          { value: 5, text: "3 year" },
          { value: 6, text: "4 year" },
          { value: 7, text: "5 year" },
          { value: 8, text: "6 year" },
          { value: 9, text: "6+ year" },
        ],
        apply_job: {
          job_id: null,
          candidate_id: null,
          status: 0,
          applier_id: null,
          applier_type: "C",
          company_id: 1,
          src: null,
        },
        c_job_type: null,
        open: null,
        user: null,
        applied: false,
        questionnaire:[],
        hasQuestionnaire:false,
        botOptions:{
          botTitle: "Questionnaire"
        },
        isApply:false,
        botTyping: false,
        inputDisable: true,
        question_index:0,
        results: [],
        expired:null,
        loading:true,
        redirect_from: null,
        jobs_loading: true,
        gender:{
          options:[
            {value:1, text:'Male'},
            {value:2, text:'Female'},
            {value:3, text:'Both'}
          ]
        },
        error:false,
        err_msg:null,
        showDismissibleAlert:false,
        readmore:false,
        jobDetails: null,
        success_alert:false,
        success_msg  :'',
        alert_class  :''
      };
    },
    methods: {
      openModal: function (type = "") {
        this.open = type == "" ? this.open : type;
        if (this.open == "success") {
          this.$refs["apply-success"].show();
        }
        this.modals.open = "exit";
        this.modals.open = type;
      },
      getUser() {
        this.action.getUser(this.$cookies.get("hire-seeker").token).then((res) => {
          this.apply_job.candidate_id = res.user.id;
          this.apply_job.applier_id = res.user.id;
        });
      },
      getCities: async function () {
        await this.action.getCities().then(async (data) => {
          if(data.cities){
            await data.cities.forEach((element) => {
              this.all_cities.push({value: element.city_id,text: element.city_name});
            });
          }
        });
      }, 
      hasDupes(array, value) {
          return array.some(function (a) {
              return a.job_id == value
          });
      },
      getJobDetails(id) {
        this.action.getJobDetailsId({ id: id }).then((res) => {
          console.log(res);
          this.loading = false;
          this.jobDetails=res.data[0].custom;
          this.job = res.data[0];
          this.apply_job.job_id = this.job.job_id;
          this.last_date_ts = this.job.last_date;
          this.seo_title = this.job.seo_title;
          this.meta_desc = this.job.seo_description;
          this.meta_keyword = this.job.seo_keywords;
          this.job.last_date = this.validate.convertTimestamp(this.job.last_date);
          if(this.last_date_ts >= new Date(new Date().setHours(0, 0, 0, 0)).getTime()/1000){
            this.expired = false;
          }else{
            this.expired = true;
          }
          this.job.courses.forEach((course, i) => {
            this.qualification += course.name;
            if (this.job.courses.length != i + 1) {
              this.qualification += ",";
            }
            this.courses.push(course.name);
            course.branches.forEach((branch) => {
              this.branches.push(branch);
              
            })
          });
          this.loading = false;
          this.filter.city_id = this.getIdsfromNames(this.job.cities, this.all_cities);
          this.filter.job_role = this.job.job_role;
          this.job.cities.forEach((city) => {
            this.jobLocation.push({
              "@type": "Place",
              address: {
                "@type": "PostalAddress",
                addressLocality: city,
                addressRegion: city,
                streetAddress: city,
                postalCode: "-",
                addressCountry: "India",
              },
            });
          });
          this.getJobrole();
          this.getJobType();
        });
      },
      getJobDescription(job){
        let param = job.job_url?job.job_url:'';
        param = param.replace(/[^A-Z0-9]+/ig, "-");
        if(param.length > 80){
          let param_split = param.split("-");
          let id_length = param_split[param_split.length-1].length;
          param = param.substring(0, 80-(id_length+1));
          param = param+'-'+param_split[param_split.length-1];
        }
        
        window.open('/jobs/'+param, '_blank').focus();
      },
      getJobrole() {
        this.action.getJobrole().then((res) => {
          res.jobroles.forEach((role) => {
            if (role.role_id == this.job.job_role) {
              this.jobrole = role;
            }
          });
        });
      },
      getJobType() {
        this.job_type.forEach((type) => {
          if (type.value == this.job.job_type) {
            this.c_job_type = type.text;
          }
        });
      },
      getJobExp(exp1, exp2) {
        var display_exp = "";
        var experienceFrom = "";
        var experienceTo = "";

        if (exp1 == null) {
          experienceFrom = "0";
        } else if(exp1 == 0){
          experienceFrom = "0 Month";
        }else if (exp1 > 0 && exp1 < 12) {
          experienceFrom = exp1 + " Months";
        } else if (exp1 == 84) {
          experienceFrom = "6+ Years";
        } else {
          experienceFrom = exp1 / 12 + " Years";
        }

        if (exp2 == 0 || exp2 == null) {
          experienceTo = "0";
        } else if (exp2 > 0 && exp2 < 12) {
          experienceTo = exp2 + " Months";
        } else if (exp2 == 84) {
          experienceTo = "6+ Years";
        } else {
          experienceTo = exp2 / 12 + " Years";
        }


        if (experienceFrom == "0" && experienceTo == "0") {
          display_exp = "";
        } else if (exp2 == exp1) {
          display_exp = experienceTo;
        } else {
          display_exp = ((experienceFrom!=null)?experienceFrom:'') + ((experienceFrom!=null && experienceTo!="0"&&experienceTo!=null)?" to ":'') + (experienceTo!="0"&&experienceTo!=null?experienceTo:'');
        }
        return display_exp;
      },
      applyJob() {
        this.apply_job.src = this.$route.query.src? this.$route.query.src: "hire-job";
          if(!this.apply_job.src.includes('-jobid')){
            this.apply_job.src += '-jobid'+this.job.job_id;
          }
          this.error=false;
        if (this.$cookies.get("hire-seeker")) {
          this.action.applyJob(this.apply_job).then((res) => {
            if (res.status == 1) {
                if(this.hasQuestionnaire) {
                  this.isApply = true;
                  this.$router.push("/questionnaire/"+this.job.job_id,)
                }else{
                  this.openModal("success");
                  setTimeout(() => {
                    this.$refs["apply-success"].hide();
                  }, 3000);
                }
                this.applied = true;
            }
          }).catch(err => {
            console.log(err);
            this.error = true;
            if(window.navigator.onLine){
              this.err_msg = "System error! Please try again.";
            }else{
              this.err_msg = "Network error! Please check your internet connection.";
            }
          });
        } else {
          this.redirect_from = this.$route.path;
          let url = '/jobseeker/register?src='+this.apply_job.src+'&&redirect_from='+this.redirect_from;
          window.open(url, "_blank").focus();
        }
      },
      getIdsfromNames(names, arr){
        let res = [];
        names.forEach(name => {
          arr.forEach(item => {
            if(item["text"] == name) res.push(item["value"]);
            })
        })
        return res;
      },
      newTab(url) {
        if (!url.match(/^https?:\/\//i)) {
            url = "https://" + url;
        }
        if (this.$cookies.get("hire-seeker")) {
          window.open(url, "_blank").focus();
        } else {
          this.apply_job.src = this.$route.query.src? this.$route.query.src: "hire-job";
          if(!this.apply_job.src.includes('-jobid')){
            this.apply_job.src += '-jobid'+this.job.job_id;
          }
          this.redirect_from = url;
          let reg_url = '/jobseeker/register?src='+this.apply_job.src+'&&redirect_from='+this.redirect_from;
          window.open(reg_url, "_blank").focus();
        }
      },
      getQuestionnaire(id){
        this.action.existQuestionnaire(id).then(res => {
          if(res.status == true){
              this.hasQuestionnaire = true;
          }else{
            this.hasQuestionnaire = false;
          }
        })
      },
      async copyToClipboard(){
        await navigator.clipboard.writeText(window.location.href);
        this.showDismissibleAlert = true;
        setTimeout(() =>{
          this.showDismissibleAlert = false;
        },3000)
      },
      getGender(id){
        this.gender.options.forEach((g) => {
          if(g.value == id){
            this.user_gender = g.text;
          }
        })
        return this.user_gender;
      }
    }
  };
</script>

<style scoped>
  /* Reset and Base Styles */
  .page-wrapper {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f8f9fa;
  }

  /* Page Header Styles */
  .page-header {
    position: relative;
    width: 100%;
    margin-bottom: 0;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    max-height: 400px;
    overflow: hidden;
  }

  .header-image {
    width: 100%;
    height: 400px;
    display: block;
    object-fit: cover;
  }

  /* Button Styles */
  .btn {
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    font-size: 1rem;
  }

  .btn-primary {
    background-color: #2563eb;
    color: white;
    box-shadow: 0 2px 4px rgba(37, 99, 235, 0.2);
  }

  .btn-primary:hover {
    background-color: #1d4ed8;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(37, 99, 235, 0.3);
  }

  .full-description {
    text-align: justify;
  }
  .btn-large {
    padding: 1rem 2rem;
    font-size: 1.1rem;
  }

  /* Alert Styles */
  .alert {
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    font-weight: 500;
  }

  .alert-danger {
    background-color: #fee2e2;
    color: #dc2626;
  }

  .alert-info {
    background-color: #e0f2fe;
    color: #0369a1;
  }

  /* Job Container Styles */
  .job-container {
    max-width: 1000px;
    margin: 1.5rem auto;
    padding: 0 1.5rem;
  }

  /* Job Header Block */
  .job-header {
    background-color: #fff;
    border-radius: 20px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 10px 30px rgba(0,0,0,0.06);
  }

  .job-title-section h1 {
    font-size: 2.25rem;
    margin-bottom: 1rem;
    color: #1e293b;
    font-weight: 700;
  }

  .company-info {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1rem 1.5rem;
    background-color: #f8fafc;
    border-radius: 16px;
    gap: 1rem;
  }

  .logo-container {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 12px;
    padding: 0.5rem;
    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
  }

  .company-logo {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
  }

  .company-info h2 {
    font-size: 1.25rem;
    color: #1e293b;
    margin-bottom: 0.25rem;
  }

  .location {
    color: #64748b;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .job-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin: 1.5rem 0;
  }

  .badge {
    background-color: #f8fafc;
    padding: 0.5rem 1rem;
    border-radius: 30px;
    font-size: 0.95rem;
    color: #475569;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
  }

  .social-links {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}

.social-links a {
    color: #6b7280;
    font-size: 1.25rem;
    transition: color 0.2s ease;
}

.social-links a:hover {
    color: #2563eb;
}

  /* Sticky Apply Button */
  .sticky-apply {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 1rem 2rem;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    z-index: 1000;
    display: flex;
    justify-content: center;
  }

  /* Job Description Styles */
  .job-description,
  .company-info-section {
    background-color: white;
    border-radius: 20px;
    padding: 2rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 10px 30px rgba(0,0,0,0.06);
  }

  .description-content {
    color: #475569;
    line-height: 1.6;
  }

  h3, h4 {
    color: #1e293b;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }

  h3 {
    font-size: 1.75rem;
    padding-bottom: 1rem;
    position: relative;
  }

  h3:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background: #2563eb;
    border-radius: 2px;
  }

  h4 {
    font-size: 1.25rem;
    margin: 1.5rem 0 1rem;
  }

  ul {
    list-style: none;
    margin-left: 0;
    margin-bottom: 2rem;
  }

  li {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    position: relative;
  }

  li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.7em;
    width: 6px;
    height: 6px;
    background: #2563eb;
    border-radius: 50%;
  }

  /* Footer Styles */
  .footer {
    background-color: #f3f4f6;
    padding: 0.75rem 1.5rem;
    margin-top: 3rem;
  }

  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  }

  .copyright {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #6b7280;
  }

  .footer-logo {
    height: 24px;
    width: auto;
  }

  .made-in {
    color: #6b7280;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .heart {
    color: #e74c3c;
  }

  
  .mdi-footer {
    color: #6b7280;
    font-size: 1.25rem;
    transition: color 0.2s ease;
  }

  .mdi-footer:hover {
    color: #2563eb;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    .header-image {
      height: 250px;
    }

    .job-container {
      padding: 0 1rem;
      margin: 1rem auto;
    }

    .job-header {
      padding: 1.25rem;
    }

    .job-title-section h1 {
      font-size: 1.75rem;
    }

    .company-info {
      padding: 0.75rem;
      gap: 0.75rem;
    }

    .job-meta {
      grid-template-columns: 1fr;
      gap: 0.5rem;
    }

    .footer-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 0.75rem;
    }
  }

  @media (max-width: 480px) {
    .header-image {
      height: 200px;
    }

    .job-title-section h1 {
      font-size: 1.5rem;
    }

    .company-info {
      flex-direction: column;
      align-items: flex-start;
    }

    .btn-large {
      width: 100%;
      padding: 0.75rem 1rem;
      font-size: 1rem;
    }
  }
</style>